const getInitials = (name = '', count = 1) => {
  if (!name) return '';
  return name
    .split(' ')
    .map(i => i.trim()[0])
    .join('')
    .slice(0, count);
};

export default getInitials;
