import * as React from 'react';

const SvgShare = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.333a2.667 2.667 0 11-1.97 4.465L6.668 7.72v.56l3.364 1.922a2.667 2.667 0 11-.625 1.179L6.179 9.537a2.667 2.667 0 110-3.074l3.227-1.844A2.667 2.667 0 0112 1.333zm0 9.334a1.333 1.333 0 100 2.666 1.333 1.333 0 000-2.666zm-8-4a1.333 1.333 0 100 2.666 1.333 1.333 0 000-2.666zm8-4a1.333 1.333 0 100 2.666 1.333 1.333 0 000-2.666z"
      fill="#151F32"
    />
  </svg>
);

export default SvgShare;
