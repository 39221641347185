import * as React from 'react';

const SvgNotifications = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 25 26'} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.515 18l1.18-1.182c.378-.378.586-.88.586-1.414v-4.677c0-1.357.59-2.654 1.62-3.556a4.66 4.66 0 013.737-1.129c2.327.309 4.082 2.413 4.082 4.895v4.467c0 .534.208 1.036.585 1.413L18.485 18H5.516zM14 20.341C14 21.24 13.084 22 12 22s-2-.76-2-1.659V20h4v.341zm6.52-3.133l-1.8-1.804v-4.467c0-3.481-2.502-6.438-5.82-6.877a6.722 6.722 0 00-5.317 1.607 6.728 6.728 0 00-2.302 5.06l-.001 4.677-1.801 1.804a1.631 1.631 0 00-.354 1.782c.255.614.848 1.01 1.512 1.01H8v.341C8 22.359 9.794 24 12 24s4-1.641 4-3.659V20h3.363c.664 0 1.256-.396 1.51-1.009a1.63 1.63 0 00-.352-1.783z"
      fill="#151F32"
    />
    <circle cx={18} cy={7} fill="#fff" r={7} />
    <circle cx={18} cy={7} r={5} fill="#3FB65F" />
  </svg>
);

export default SvgNotifications;
