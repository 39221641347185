import * as React from 'react';

const SvgAddwidget = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 24 24'} fill="none" {...props}>
    <path
      d="M9.5 4.5h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1zM18.5 4.5h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1zM9.5 13.5h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1z"
      stroke="#151F32"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.613 16.5h5.774M16.5 13.613v5.774"
      stroke="#151F32"
      strokeWidth={1.7}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgAddwidget;
