import * as React from 'react';

const SvgPlus = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 12 12'} fill="none" {...props}>
    <path
      d="M6 11.55h0a.6.6 0 01-.6-.6s0 0 0 0V6.601H1.05s0 0 0 0a.6.6 0 01-.6-.6L6 11.55zm0 0a.6.6 0 00.6-.6V6.6M6 11.55l.6-4.95m0 0h4.35a.599.599 0 00.6-.6l-4.95.6zM1.05 5.4a.6.6 0 00-.6.6L6 .552v-.1s0 0 0 0a.6.6 0 00-.6.6v4.35H1.05s0 0 0 0zm5.55 0V1.053L11.55 6a.6.6 0 00-.6-.6s0 0 0 0H6.6z"
      fill="#151F32"
      stroke="#151F32"
      strokeWidth={0.2}
    />
  </svg>
);

export default SvgPlus;
