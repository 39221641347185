import * as React from 'react';

const SvgArrowdown = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 12 12'} fill="none" {...props}>
    <path
      d="M10.896 3.9c0 .213-.076.381-.217.528L6.61 8.595c-.184.185-.374.272-.608.277a.798.798 0 01-.609-.277L1.317 4.428A.735.735 0 011.1 3.9c0-.424.337-.761.755-.761.207 0 .402.087.554.24l3.592 3.694 3.586-3.695a.783.783 0 01.554-.239c.419 0 .755.337.755.76z"
      fill="#18191B"
    />
  </svg>
);

export default SvgArrowdown;
