import * as React from 'react';

const SvgRain = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <path
      d="M10.917 12.667a.584.584 0 00-.584.583v1.167a.584.584 0 001.167 0V13.25a.583.583 0 00-.583-.583zM6.833 12.667a.584.584 0 00-.583.583v1.167a.584.584 0 001.167 0V13.25a.584.584 0 00-.584-.583zM11.208 3.54c-.157 0-.314.01-.47.029C10.246 2.069 8.79 1 7.125 1c-2.09 0-3.792 1.654-3.792 3.688l.001.098C1.97 5.156 1 6.362 1 7.793c0 1.722 1.44 3.124 3.208 3.124v.875a.584.584 0 001.167 0v-.875h2.917v.875a.584.584 0 001.166 0v-.875h1.75C13.3 10.917 15 9.262 15 7.229c0-2.034-1.701-3.689-3.792-3.689zm0 6.21h-7c-1.125 0-2.041-.878-2.041-1.957 0-1.019.797-1.857 1.855-1.948a.583.583 0 00.521-.698 2.282 2.282 0 01-.043-.459c0-1.39 1.177-2.521 2.625-2.521 1.302 0 2.42.932 2.599 2.167a.584.584 0 00.738.478c.242-.07.493-.105.747-.105 1.447 0 2.625 1.131 2.625 2.522 0 1.39-1.178 2.521-2.626 2.521z"
      fill="#151F32"
    />
  </svg>
);

export default SvgRain;
