import * as React from 'react';

const SvgInfo = (props: React.SVGProps<any>) => (
  <svg viewBox="0 0 16 16" fill="#151F32" {...props}>
    <path
      d="M8 1C4.1402 1 1 4.14024 1 8.00004C1 11.8598 4.1402 15 8 15C11.8598 15 15 11.8598 15 8.00004C15 4.14024 11.8598 1 8 1ZM8 13.7273C4.84194 13.7273 2.27273 11.1581 2.27273 8.00004C2.27273 4.84202 4.84194 2.27273 8 2.27273C11.1581 2.27273 13.7273 4.84202 13.7273 8.00004C13.7273 11.1581 11.158 13.7273 8 13.7273Z"
    />
    <path
      d="M8.00066 3.96948C7.53289 3.96948 7.15234 4.35028 7.15234 4.81835C7.15234 5.28599 7.53289 5.66645 8.00066 5.66645C8.46843 5.66645 8.84897 5.28599 8.84897 4.81835C8.84897 4.35028 8.46843 3.96948 8.00066 3.96948Z"
    />
    <path
      d="M7.99965 6.93945C7.6482 6.93945 7.36328 7.22437 7.36328 7.57582V11.394C7.36328 11.7454 7.6482 12.0304 7.99965 12.0304C8.35109 12.0304 8.63601 11.7454 8.63601 11.394V7.57582C8.63601 7.22437 8.35109 6.93945 7.99965 6.93945Z"
    />
  </svg>
);

export default SvgInfo;
