import * as React from 'react';

const SvgAccountsettings = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <path
      d="M7.134 6.202a2.934 2.934 0 110-5.869 2.934 2.934 0 010 5.869zm0-4.513a1.578 1.578 0 100 3.157 1.578 1.578 0 000-3.157z"
      fill="#151F32"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.853 7.699a4.67 4.67 0 00-1.313.837A4.688 4.688 0 007 8.286a4.597 4.597 0 00-2.738.87 4.032 4.032 0 00-1.551 2.265.202.202 0 00.05.192c.026.034.061.061.102.08.04.018.085.027.13.025h4.014a4.738 4.738 0 00.1 1.282H3.004a1.77 1.77 0 01-.732-.153 1.643 1.643 0 01-.584-.437 1.46 1.46 0 01-.316-.607 1.395 1.395 0 01.003-.674c.298-1.175 1.016-2.222 2.037-2.972A6.071 6.071 0 017 7c1.005 0 1.988.243 2.853.699z"
      fill="#151F32"
    />
    <path
      d="M14.896 10.583a9.657 9.657 0 00-.641-.362l-.207-.5c.061-.165.179-.628.197-.709a.222.222 0 00-.06-.207l-.323-.323a.222.222 0 00-.207-.06c-.06.014-.537.133-.71.198l-.5-.207a9.49 9.49 0 00-.361-.642.222.222 0 00-.188-.104h-.458a.222.222 0 00-.189.104 9.582 9.582 0 00-.362.642l-.5.207a9.53 9.53 0 00-.709-.198.222.222 0 00-.206.06l-.324.324a.222.222 0 00-.06.206c.014.061.133.537.198.71l-.207.5c-.16.073-.57.317-.642.362a.222.222 0 00-.104.188v.458c0 .076.04.147.104.188.054.034.475.285.642.362l.207.5c-.062.165-.18.629-.198.71a.222.222 0 00.06.206l.324.324a.222.222 0 00.206.06c.061-.015.537-.134.71-.198l.5.207c.073.16.317.57.361.64.041.066.112.105.189.105h.458c.076 0 .147-.04.188-.104.034-.054.285-.474.362-.641l.5-.207c.165.061.629.179.71.197a.223.223 0 00.206-.06l.324-.323a.222.222 0 00.06-.207 9.612 9.612 0 00-.198-.71l.207-.5c.16-.072.57-.317.64-.361A.222.222 0 0015 11.23v-.458a.222.222 0 00-.104-.189zm-3.23 1.87a1.455 1.455 0 01-1.453-1.452 1.455 1.455 0 012.906 0c0 .8-.651 1.453-1.452 1.453z"
      fill="#9AA0AD"
    />
  </svg>
);

export default SvgAccountsettings;
