import * as React from 'react';

const SvgCartbuy = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 24 24'} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 7.711C4 7.318 4.318 7 4.711 7h1.911c.337 0 .627.236.696.566l.476 2.278H16.8c.785 0 1.422.637 1.422 1.423v3.075c0 .673-.472 1.255-1.13 1.392l-7.728 1.617A1.422 1.422 0 017.68 16.25L6.044 8.422H4.711A.711.711 0 014 7.712zm4.925 7.552c.08.384.457.63.842.55l6.467-1.352a.711.711 0 00.566-.696v-1.787a.711.711 0 00-.711-.711H8.09l.834 3.996z"
      fill="#151F32"
    />
    <circle cx={8.525} cy={19.929} r={1.293} fill="#151F32" />
    <circle cx={14.99} cy={19.929} r={1.293} fill="#151F32" />
    <path
      d="M11.865 1.44v4.95M9.84 4.365l2.025 2.025 2.025-2.025"
      stroke="#151F32"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgCartbuy;
