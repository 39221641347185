import styled from "styled-components";
import { AlignType, VariantsType } from "./Typography";
import { ColorsThemeType } from "../configs/Colors/types";


const ellipsisStyle = `
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const TypographyWrapper = styled.div<{
    align: AlignType;
    noWrap: boolean;
    variant: VariantsType;
    ellipsis: boolean;
    color: ColorsThemeType | 'inherit';
    hoverColor?: ColorsThemeType;
}>`
  margin: 0;
  padding: 0;
  color: ${({ color, theme }) => color === 'inherit' ? color : theme.Colors[color]};
  
  white-space: ${({noWrap}) => noWrap ? 'nowrap' : 'normal'};
  text-align: ${({align}) => align};
  ${({theme, variant}) => theme.Typography.schemas[variant]};
  
  ${({ ellipsis }) => ellipsis && ellipsisStyle}
  
  ${({theme, hoverColor}) => hoverColor ? `
    &:hover {
        cursor: pointer;
        color: ${theme.Colors[hoverColor]};
    }
  ` : ''}
`;
