import * as React from 'react';

const SvgSettings = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 24 24'} fill="none" {...props}>
    <path
      d="M9.5 4.5h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1zM18.5 4.5h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1zM9.5 13.5h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1z"
      stroke="#151F32"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.035 16.293c-.07-.044-.617-.372-.834-.471l-.27-.65c.08-.215.233-.819.257-.923a.289.289 0 00-.077-.269l-.421-.42a.289.289 0 00-.269-.078c-.08.018-.698.173-.923.257l-.65-.27a12.354 12.354 0 00-.47-.834.289.289 0 00-.245-.135h-.596c-.1 0-.192.051-.244.135a12.5 12.5 0 00-.471.834l-.65.27c-.215-.08-.819-.233-.923-.257a.289.289 0 00-.269.077l-.42.421c-.071.07-.1.172-.078.269.018.08.173.698.257.923l-.27.65c-.208.095-.742.413-.834.47a.289.289 0 00-.135.245v.596c0 .1.051.192.135.245.07.043.617.37.834.47l.27.65c-.08.215-.233.819-.257.923a.289.289 0 00.077.269l.421.42c.07.071.172.1.269.078.08-.018.698-.173.923-.256l.65.269c.095.208.413.742.47.834a.289.289 0 00.245.135h.596c.1 0 .192-.051.245-.135a12.5 12.5 0 00.47-.834l.65-.27c.215.08.819.233.923.257a.289.289 0 00.269-.077l.42-.421c.071-.07.1-.172.078-.269-.018-.08-.173-.698-.256-.923l.269-.65c.208-.095.742-.413.834-.47a.289.289 0 00.135-.245v-.596c0-.1-.051-.192-.135-.244zm-4.2 2.432a1.892 1.892 0 01-1.89-1.89c0-1.042.848-1.89 1.89-1.89 1.042 0 1.89.848 1.89 1.89 0 1.042-.848 1.89-1.89 1.89z"
      fill="#9AA0AD"
    />
  </svg>
);

export default SvgSettings;
