import * as React from 'react';

const SvgHumidity = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <path
      d="M10.072 12.73a.547.547 0 01-.008.774C9.079 14.469 7.754 15 6.332 15a5.315 5.315 0 01-3.769-1.546A5.256 5.256 0 011 9.694V9.55C1 7.7 2.544 5.929 3.906 4.366c.924-1.06 1.88-2.156 1.88-2.82a.547.547 0 111.093 0c0 .707.969 1.81 1.906 2.875.17.194.347.395.52.597a.547.547 0 01-.827.714c-.171-.198-.346-.396-.515-.588-.642-.73-1.235-1.406-1.64-2.052-.399.623-.973 1.282-1.593 1.993-1.235 1.418-2.636 3.026-2.636 4.464v.146c0 2.322 1.901 4.211 4.238 4.211 1.134 0 2.187-.42 2.966-1.183a.547.547 0 01.774.008zm4.381-2.023c-.21 0-.497-.198-.785-.543a1.25 1.25 0 00-.965-.441c-.379 0-.73.16-.965.441-.288.345-.574.543-.785.543-.21 0-.497-.198-.785-.543a1.25 1.25 0 00-.965-.441c-.379 0-.73.16-.965.441-.288.345-.574.543-.785.543-.21 0-.497-.198-.785-.543a1.25 1.25 0 00-.965-.441c-.379 0-.73.16-.965.441-.288.345-.574.543-.785.543a.547.547 0 100 1.094c.56 0 1.106-.315 1.625-.936.025-.03.072-.049.125-.049.053 0 .1.019.126.05.518.62 1.065.935 1.624.935.56 0 1.106-.315 1.625-.936.025-.03.072-.049.125-.049.053 0 .1.019.126.05.518.62 1.064.935 1.624.935s1.106-.315 1.625-.936c.025-.03.072-.049.125-.049.053 0 .1.019.126.05.518.62 1.064.935 1.624.935a.547.547 0 100-1.094zM8.238 7.43c-.288.345-.574.543-.785.543a.547.547 0 100 1.093c.56 0 1.106-.314 1.625-.935.025-.03.072-.049.125-.049.053 0 .1.018.126.05.518.62 1.064.934 1.624.934s1.106-.314 1.625-.935c.025-.03.072-.049.125-.049.053 0 .1.018.126.05.518.62 1.064.934 1.624.934a.547.547 0 100-1.093c-.21 0-.497-.198-.785-.543a1.25 1.25 0 00-.965-.442c-.379 0-.73.161-.965.442-.288.345-.574.543-.785.543-.21 0-.497-.198-.785-.543a1.25 1.25 0 00-.965-.442c-.379 0-.73.161-.965.442z"
      fill="#151F32"
    />
  </svg>
);

export default SvgHumidity;
