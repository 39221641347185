import * as React from 'react';

const SvgDew = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <path
      d="M7.645 6.496H6.55a.547.547 0 110-1.094h1.094a.547.547 0 110 1.094zm-.92 7.535a3.34 3.34 0 001.002-2.394c0-.631-.176-1.246-.51-1.78a.804.804 0 01-.12-.426v-.747h.548a.547.547 0 100-1.094h-.547c-.603 0-1.094.49-1.094 1.094v.747c0 .358.099.706.286 1.006.224.359.343.774.343 1.2 0 .612-.24 1.186-.676 1.616a2.253 2.253 0 01-1.594.653h-.032a2.286 2.286 0 01-2.237-2.227c-.008-.44.11-.87.342-1.24a1.9 1.9 0 00.287-1.008V3.76c0-.897.745-1.66 1.628-1.667h.012c.778 0 1.453.55 1.608 1.31.103.509.554.877 1.072.877h.602a.547.547 0 100-1.093l-.602-.001A2.739 2.739 0 004.343 1c-.723.005-1.405.3-1.921.828a2.758 2.758 0 00-.793 1.934V9.43a.808.808 0 01-.121.428 3.353 3.353 0 00.485 4.159 3.344 3.344 0 002.37.982c.89 0 1.728-.343 2.362-.969zm7.619-9.425c0-.208 0-.84-1.267-2.448a1.166 1.166 0 00-.92-.447c-.362 0-.698.163-.922.447C9.97 3.766 9.97 4.398 9.97 4.606c0 1.208.981 2.19 2.187 2.19a2.192 2.192 0 002.188-2.19zm-2.126-1.771c.815 1.034 1.032 1.6 1.032 1.771 0 .605-.49 1.097-1.094 1.097a1.097 1.097 0 01-1.094-1.097c0-.171.218-.737 1.032-1.771a.075.075 0 01.062-.03c.026 0 .046.01.062.03zM15 11.719c0-.448-.205-1.01-.627-1.72-.28-.47-.65-.997-1.1-1.567a1.415 1.415 0 00-1.117-.541c-.438 0-.846.197-1.117.541-.45.57-.82 1.097-1.1 1.567-.421.71-.627 1.272-.627 1.72a2.847 2.847 0 002.844 2.844A2.847 2.847 0 0015 11.719zm-2.585-2.61c1.1 1.394 1.491 2.262 1.491 2.61 0 .965-.785 1.75-1.75 1.75s-1.75-.785-1.75-1.75c0-.348.392-1.216 1.491-2.61a.325.325 0 01.26-.125c.059 0 .172.017.258.126zM3.68 11.638a.684.684 0 111.367 0 .684.684 0 01-1.367 0zm1.23-2.325V5.95a.547.547 0 10-1.094 0v3.364a.547.547 0 101.094 0z"
      fill="#151F32"
    />
  </svg>
);

export default SvgDew;
