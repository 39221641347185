import { ECardItemSize } from '../types';

export const getFontsCardItemTitle = (size: ECardItemSize = ECardItemSize.Large) => {
  const variants: Record<ECardItemSize, string> = {
    [ECardItemSize.Medium]: `
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    `,
    [ECardItemSize.Large]: `      
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      letter-spacing: -0.15px;
    `,
  };

  const result = variants[size];

  if (!result) {
    return variants[ECardItemSize.Large];
  }

  return variants[size];
};

export const getWidthCardItemContent = (size: ECardItemSize = ECardItemSize.Large) => {
  const variants: Record<ECardItemSize, string> = {
    [ECardItemSize.Medium]: `
      width: calc(100% - 36px);
    `,
    [ECardItemSize.Large]: `
      width: calc(100% - 44px);
    `,
  };

  const result = variants[size];

  if (!result) {
    return variants[ECardItemSize.Large];
  }

  return variants[size];
};

export const getSizeAvatar = (size: ECardItemSize = ECardItemSize.Large) => {
  const variants: Record<ECardItemSize, string> = {
    [ECardItemSize.Medium]: `
      width: 24px;
      height: 24px;
      min-width: 24px;      
      margin-right: 8px;
      border-radius: 50%;
    `,
    [ECardItemSize.Large]: `
      width: 32px;
      height: 32px;
      min-width: 32px;      
      margin-right: 12px;
      border-radius: 12px;
    `,
  };

  const result = variants[size];

  if (!result) {
    return variants[ECardItemSize.Large];
  }

  return variants[size];
};
