import * as React from 'react';

const SvgUser = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 24 24'} fill="none" {...props}>
    <path
      d="M18.065 13.109h0a.881.881 0 00-.716 1.61h0c.77.343 1.432.755 1.967 1.226l.066-.075-.066.075a3.016 3.016 0 011.021 2.266v1.445a.682.682 0 01-.68.681H4.343a.682.682 0 01-.682-.68V18.21c0-.87.373-1.696 1.022-2.266l-.066-.075.066.075c.774-.68 3.05-2.283 7.316-2.283a5.888 5.888 0 005.881-5.88A5.888 5.888 0 0012 1.9a5.888 5.888 0 00-5.881 5.881c0 1.821.832 3.451 2.135 4.53-2.413.568-3.929 1.602-4.734 2.31a4.778 4.778 0 00-1.62 3.59v1.445A2.446 2.446 0 004.344 22.1h15.312a2.446 2.446 0 002.444-2.444v-1.445c0-1.377-.59-2.685-1.62-3.59-.672-.59-1.485-1.099-2.415-1.512zM7.88 7.78A4.124 4.124 0 0112 3.662a4.124 4.124 0 014.119 4.12A4.124 4.124 0 0112 11.9a4.124 4.124 0 01-4.119-4.119z"
      fill="#151F32"
      stroke="#151F32"
      strokeWidth={0.2}
    />
  </svg>
);

export default SvgUser;
