import * as React from 'react';

const SvgMenu = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <path
      d="M15.2 13.6H.8a.8.8 0 000 1.6h14.4a.8.8 0 000-1.6zM.8 2.4h14.4a.8.8 0 000-1.6H.8a.8.8 0 000 1.6zm14.4 4.8H.8a.8.8 0 000 1.6h14.4a.8.8 0 000-1.6z"
      fill="#151F32"
    />
  </svg>
);

export default SvgMenu;
