import * as React from 'react';

const SvgClose = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 24 24'} fill="none" {...props}>
    <path
      d="M13.592 12l7.079-7.078a1.125 1.125 0 10-1.592-1.592l-7.078 7.078L4.92 3.33A1.125 1.125 0 103.33 4.922L10.409 12l-7.08 7.079a1.125 1.125 0 101.593 1.591L12 13.592l7.078 7.078a1.122 1.122 0 001.592 0c.44-.44.44-1.152 0-1.592L13.592 12z"
      fill="#151F32"
    />
  </svg>
);

export default SvgClose;
