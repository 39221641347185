import * as React from 'react';

const SvgSearch = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.874 10.84l-.042.053 2.563 2.563a.663.663 0 11-.939.94l-2.563-2.564-.054.042a5.814 5.814 0 01-3.58 1.234A5.856 5.856 0 011.41 7.26 5.856 5.856 0 017.26 1.41a5.856 5.856 0 015.848 5.85c0 1.349-.464 2.59-1.234 3.58zM7.259 2.737A4.525 4.525 0 002.74 7.26a4.525 4.525 0 004.52 4.521 4.525 4.525 0 004.521-4.52 4.525 4.525 0 00-4.52-4.522z"
      fill="#151F32"
    />
  </svg>
);

export default SvgSearch;
