import * as React from 'react';

const SvgMap = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 24 28'} fill="none" {...props}>
    <g filter="url(#map_svg__filter0_d)">
      <path
        d="M12 0C7.207 0 3.308 3.9 3.308 8.692c0 5.948 7.778 14.68 8.11 15.048a.783.783 0 001.164 0c.332-.368 8.11-9.1 8.11-15.048C20.692 3.899 16.792 0 12 0zm0 13.065a4.378 4.378 0 01-4.373-4.373A4.378 4.378 0 0112 4.319a4.378 4.378 0 014.373 4.373A4.378 4.378 0 0112 13.065z"
        fill="#151F32"
      />
    </g>
    <defs>
      <filter
        id="map_svg__filter0_d"
        x={-2}
        y={0}
        width={28}
        height={28}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default SvgMap;
