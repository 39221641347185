import * as React from 'react';

const SvgArrowleft = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 12 12'} fill="none" {...props}>
    <path
      d="M7.972 1.023a.726.726 0 00-.527.218L3.277 5.31c-.185.184-.272.374-.277.608 0 .233.087.429.277.608l4.168 4.075c.146.142.32.218.527.218.423 0 .76-.337.76-.755a.783.783 0 00-.239-.555L4.798 5.92l3.695-3.586a.783.783 0 00.24-.555.755.755 0 00-.761-.755z"
      fill="#18191B"
    />
  </svg>
);

export default SvgArrowleft;
