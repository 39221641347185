import * as React from 'react';

const SvgCatalog = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <g clipPath="url(#catalog_svg__clip0)">
      <path
        d="M10.333 5.42A9.48 9.48 0 008 7.173 9.16 9.16 0 005.667 5.42 8.169 8.169 0 018.04.333c1.413 1.414 2.167 3.227 2.293 5.087zM8 9.3c1.433-2.187 3.88-3.633 6.667-3.633 0 6.666-6.214 7.92-6.667 8-.453-.074-6.667-1.334-6.667-8C4.12 5.667 6.567 7.113 8 9.3zm.033-6.84a6.804 6.804 0 00-.913 2.26l.88.647.9-.654a6.765 6.765 0 00-.867-2.253zM8 12.313s4-.646 5.16-5.146C9.333 8.333 8 11.733 8 11.733s-2-4.066-5.16-4.56C4 11.667 8 12.313 8 12.313zM-1160.33-770.58a9.53 9.53 0 00-2.34 1.753 9.107 9.107 0 00-2.33-1.753 8.2 8.2 0 012.37-5.087c1.42 1.414 2.17 3.227 2.3 5.087zM1114 722.333h66.67V789H1114v-66.667z"
        fill="#151F32"
      />
      <path
        d="M10.333 5.42A9.479 9.479 0 008 7.173 9.161 9.161 0 005.667 5.42 8.17 8.17 0 018.04.333c1.413 1.414 2.167 3.227 2.293 5.087zm-2.3-2.96a6.804 6.804 0 00-.913 2.26l.88.647.9-.654a6.763 6.763 0 00-.867-2.253zM-1160.33-770.58a9.53 9.53 0 00-2.34 1.753 9.107 9.107 0 00-2.33-1.753 8.2 8.2 0 012.37-5.087c1.42 1.414 2.17 3.227 2.3 5.087zM1114 722.333h66.67V789H1114v-66.667z"
        fill="#9AA0AD"
      />
    </g>
    <defs>
      <clipPath id="catalog_svg__clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgCatalog;
