import React, { FC, HTMLAttributes } from 'react';

export interface AvatarProps extends HTMLAttributes<HTMLDivElement> {
  src: string;
  alt?: string;
  dataTestId?: string;
}

export const Avatar: FC<AvatarProps> = ({ src, alt = '', dataTestId = '' }) => {
  return <img src={src} alt={alt} data-test-id={dataTestId} />;
};
