import * as React from 'react';

const SvgComments = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <g clipPath="url(#comments_svg__clip0)">
      <path
        d="M8 14.65a6.624 6.624 0 003.021-.725l1.949.487s0 0 0 0c.474.119.896 0 1.17-.272.273-.273.391-.695.272-1.17 0 0 0 0 0 0l-.487-1.949A6.65 6.65 0 108 14.65zm0 0v-.05.05s0 0 0 0zm4.603-3.557l.049-.012-.049.012.504 2.014-2.014-.504-.012.049.012-.049a.65.65 0 00-.476.064A5.35 5.35 0 1113.35 8v0a5.322 5.322 0 01-.683 2.617.65.65 0 00-.064.476zM4.807 7.06a.65.65 0 00.46.19h5.4a.65.65 0 100-1.3h-5.4a.65.65 0 00-.46 1.11zm0 2.666a.65.65 0 00.46.19h3.6a.65.65 0 000-1.3h-3.6a.65.65 0 00-.46 1.11z"
        fill="#151F32"
        stroke="#151F32"
        strokeWidth={0.1}
      />
    </g>
    <defs>
      <clipPath id="comments_svg__clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComments;
