import * as React from 'react';

const SvgCheck = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 10 10'} fill="none" {...props}>
    <path d="M5 10C7.73529 10 10 7.73529 10 5C10 2.26961 7.73039 0 4.9951 0C2.26471 0 0 2.26961 0 5C0 7.73529 2.26961 10 5 10ZM5 9.16667C2.68627 9.16667 0.838235 7.31373 0.838235 5C0.838235 2.69118 2.68137 0.833333 4.9951 0.833333C7.30882 0.833333 9.16177 2.69118 9.16667 5C9.17157 7.31373 7.31373 9.16667 5 9.16667ZM4.38235 7.45098C4.54412 7.45098 4.67647 7.37255 4.77941 7.22059L7.20098 3.41176C7.26471 3.31373 7.31863 3.20098 7.31863 3.09314C7.31863 2.86765 7.12255 2.73039 6.91667 2.73039C6.78922 2.73039 6.66176 2.80392 6.57353 2.95098L4.36275 6.4951L3.22549 5.02451C3.11275 4.87745 2.9951 4.82353 2.86275 4.82353C2.64706 4.82353 2.47549 5 2.47549 5.22059C2.47549 5.32843 2.51961 5.43137 2.59314 5.52451L3.96078 7.22059C4.09314 7.38235 4.22059 7.45098 4.38235 7.45098Z"/>
  </svg>
);

export default SvgCheck;


