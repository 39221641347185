import * as React from 'react';

const SvgWind = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <path
      d="M12.083 2.75a2.92 2.92 0 00-2.916 2.917v.583h1.166v-.583c0-.965.785-1.75 1.75-1.75.966 0 1.75.785 1.75 1.75s-.784 1.75-1.75 1.75H1v1.166h11.083A2.92 2.92 0 0015 5.667a2.92 2.92 0 00-2.917-2.917z"
      fill="#151F32"
    />
    <path
      d="M8 3.917v-.584A2.336 2.336 0 005.667 1a2.336 2.336 0 00-2.334 2.333v.584H4.5v-.584a1.168 1.168 0 012.333 0v.584c0 .643-.523 1.166-1.166 1.166H1V6.25h4.667A2.336 2.336 0 008 3.917zM10.333 12.083v.584a1.168 1.168 0 01-2.333 0v-.584H6.833v.584A2.336 2.336 0 009.167 15a2.336 2.336 0 002.333-2.333v-.584A2.336 2.336 0 009.167 9.75H1v1.167h8.167c.643 0 1.166.523 1.166 1.166z"
      fill="#151F32"
    />
  </svg>
);

export default SvgWind;
