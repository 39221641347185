import React, { FC } from 'react';
import styled from 'styled-components';
import { Checkbox } from '../../../../../../Checkbox';
import { Colors } from '../../../../../../configs/Colors';

export type TShowAgainToggleProps = {
  onToggle: (flag: boolean) => void;
};

const ShowAgainToggle: FC<TShowAgainToggleProps> = ({ onToggle }) => {
  return <StyledCheckbox onChange={onToggle} label="Больше не показывать" />;
};

const StyledCheckbox = styled(Checkbox)`
  margin-top: 24px;

  & p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${Colors.secondaryDarkGray};
  }
`;

export default ShowAgainToggle;
