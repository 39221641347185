import * as React from 'react';

const SvgSunset = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <path
      d="M8 6.946c-.227 0-.41.198-.41.442v.472c0 .244.183.442.41.442.227 0 .41-.198.41-.442v-.472c0-.244-.183-.442-.41-.442zM12.95 9.153a.39.39 0 00-.58 0l-.31.335a.466.466 0 000 .624c.08.086.184.13.29.13.104 0 .21-.044.29-.13l.31-.334a.466.466 0 000-.625zM3.941 9.488l-.31-.335a.39.39 0 00-.58 0 .466.466 0 000 .625l.31.334c.08.086.185.13.29.13.105 0 .21-.044.29-.13a.466.466 0 000-.624zM10.154 3.26a.39.39 0 00-.58 0L8.41 4.514V1.37c0-.244-.183-.441-.41-.441-.226 0-.41.197-.41.441v3.143L6.426 3.261a.39.39 0 00-.58 0 .466.466 0 000 .624L7.71 5.891c.08.087.185.13.29.13.105 0 .21-.043.29-.13l1.864-2.006a.466.466 0 000-.624zM14.59 14.141h-2.245c-.208-2.32-2.077-4.14-4.345-4.14s-4.137 1.82-4.345 4.14H1.41a.42.42 0 00-.41.43c0 .237.184.43.41.43h13.18a.42.42 0 00.41-.43.42.42 0 00-.41-.43zm-10.11 0c.204-1.845 1.705-3.282 3.52-3.282s3.316 1.437 3.52 3.282H4.48z"
      fill="#151F32"
    />
  </svg>
);

export default SvgSunset;
