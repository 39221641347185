import * as React from 'react';

const SvgSuggestion = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <path
      d="M7.973 11.965a.547.547 0 110-1.094.547.547 0 010 1.094zm.547-2.461V8.77a2.279 2.279 0 001.748-2.3c-.046-1.182-1.015-2.142-2.207-2.187a2.293 2.293 0 00-1.692.642 2.24 2.24 0 00-.693 1.626.547.547 0 101.094 0c0-.319.127-.616.357-.838.239-.23.556-.35.893-.337a1.197 1.197 0 011.155 1.136 1.187 1.187 0 01-.939 1.2c-.477.105-.81.518-.81 1.004v.788a.547.547 0 101.094 0zm3.102 4.487a.547.547 0 10-.567-.935 5.895 5.895 0 01-3.055.85A5.913 5.913 0 012.094 8 5.913 5.913 0 018 2.094 5.913 5.913 0 0113.906 8a5.883 5.883 0 01-1.001 3.278.547.547 0 10.909.608A6.972 6.972 0 0015 8c0-1.87-.728-3.628-2.05-4.95A6.954 6.954 0 008 1c-1.87 0-3.628.728-4.95 2.05A6.954 6.954 0 001 8c0 1.87.728 3.628 2.05 4.95A6.954 6.954 0 008 15c1.28 0 2.533-.349 3.622-1.009z"
      fill="#151F32"
    />
  </svg>
);

export default SvgSuggestion;
