import * as React from 'react';

const SvgHeart = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <path
      d="M8 14.21l-.033-.016-.244-.112-.003-.001h0a14.786 14.786 0 01-3.203-2.152C2.403 10.067 1.253 8.357 1.253 6.09L8 14.209zm0 0l.033-.016M8 14.209l.033-.015m0 0l.244-.112h0l.003-.001a14.79 14.79 0 003.203-2.148h0m-3.45 2.26l3.45-2.26m0 0c2.114-1.875 3.264-3.575 3.264-5.842m-3.263 5.842L1.254 6.09c0-.766.218-1.552.632-2.205a4.214 4.214 0 011.711-1.524A4.368 4.368 0 018 2.699a4.367 4.367 0 014.403-.338 4.215 4.215 0 011.71 1.525c.415.653.634 1.439.634 2.205M8 12.736l.038.02-.038.07c1.098-.587 5.537-3.3 5.537-6.735 0-.579-.174-1.18-.502-1.663a3.028 3.028 0 00-1.343-1.092 3.134 3.134 0 00-1.74-.194 3.083 3.083 0 00-1.563.767l.054.06M8 12.735c.57-.309 1.913-1.12 3.125-2.268 1.243-1.177 2.332-2.694 2.332-4.377v0c0-.564-.17-1.149-.489-1.618a2.948 2.948 0 00-1.307-1.063 3.054 3.054 0 00-1.696-.19c-.572.1-1.1.36-1.522.748M8 12.736a15.831 15.831 0 01-3.126-2.268c-1.242-1.177-2.33-2.694-2.33-4.377a2.92 2.92 0 01.87-2.07 3.023 3.023 0 012.1-.845c.761 0 1.492.284 2.043.792L8 12.736zm.443-8.768s0 0 0 0m0 0h0m0 0l-.389.36-.054.05-.054-.05-.389-.36h.886zm6.304 2.123h-.08.08z"
      fill="#151F32"
      stroke="#151F32"
      strokeWidth={0.16}
    />
  </svg>
);

export default SvgHeart;
