import * as React from 'react';

const SvgSmartphone = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 20 24'} fill="none" {...props}>
    <path
      d="M15.155 16.93a.401.401 0 00-.402.4v4.244c0 .923-.75 1.673-1.673 1.673H2.937c-.923 0-1.674-.75-1.674-1.673V2.426c0-.923.75-1.673 1.674-1.673H13.08c.922 0 1.673.75 1.673 1.673v2.287a.401.401 0 10.803 0V2.426A2.479 2.479 0 0013.08-.05H2.937A2.479 2.479 0 00.46 2.426v19.148a2.479 2.479 0 002.477 2.476H13.08a2.479 2.479 0 002.476-2.476V17.33a.401.401 0 00-.401-.4z"
      fill="#151F32"
      stroke="#151F32"
      strokeWidth={0.1}
    />
    <path
      d="M11.411 1.716h0l-.056.157h0a.522.522 0 01-.49.344H7.663a.401.401 0 000 .803h3.202c.552 0 1.048-.346 1.24-.862h.975c.148 0 .268.12.268.268v2.275a.401.401 0 10.803 0V2.426c0-.59-.48-1.071-1.071-1.071h-1.155a.548.548 0 00-.514.361z"
      fill="#151F32"
      stroke="#151F32"
      strokeWidth={0.1}
    />
    <path
      d="M11.747 6.52h0a4.532 4.532 0 012.658-.854 4.568 4.568 0 014.563 4.564c0 .934-.58 2.129-1.705 3.548a24.35 24.35 0 01-2.333 2.508l-.034-.037.034.037a.766.766 0 01-1.05 0 24.33 24.33 0 01-2.333-2.508c-1.126-1.419-1.705-2.614-1.705-3.548 0-.905.264-1.78.765-2.53a.401.401 0 01.557-.111m.583-1.07l2.682 9.18h0m-2.682-9.18a.401.401 0 00-.092.56l.092-.56zm-.583 1.07l-.028.041.028-.041s0 0 0 0zm0 0a.401.401 0 01.11.556m0 0l-.041-.028.041.028zm0 0a3.742 3.742 0 00-.63 2.085c0 .348.12.757.33 1.203.212.446.513.925.872 1.414.711.97 1.65 1.977 2.559 2.83m0 0l-.024.023h0a.035.035 0 01.047 0m-.023-.022c.91-.854 1.847-1.86 2.56-2.83.358-.49.659-.97.87-1.415.211-.446.33-.854.33-1.203a3.765 3.765 0 00-3.76-3.761c-.792 0-1.549.243-2.19.703m2.19 8.506l.023.022m0 0l-2.213-8.528m0 0l-.029-.04m.03.04l6 3.058c0-2.101-1.71-3.811-3.811-3.811-.802 0-1.57.246-2.219.712m.03.041s0 0 0 0l-.03-.04m.03.04l-.03-.04"
      fill="#3FB65F"
      stroke="#3FB65F"
      strokeWidth={0.1}
    />
    <path
      d="M16.978 10.23a2.576 2.576 0 00-2.573-2.573 2.576 2.576 0 00-2.573 2.573 2.576 2.576 0 002.573 2.573 2.576 2.576 0 002.573-2.573zm-4.344 0c0-.976.795-1.77 1.77-1.77.977 0 1.771.794 1.771 1.77S15.381 12 14.405 12s-1.77-.794-1.77-1.77z"
      fill="#3FB65F"
      stroke="#3FB65F"
      strokeWidth={0.1}
    />
    <path
      d="M9.932 13.368l-.033-.035a.401.401 0 01.567.009m-.534.026l.498.009m-.498-.009l-.033-.035-2.488 2.414m2.521-2.379l-2.52 2.38m3.054-2.406l-.036.035m.036-.035s0 0 0 0l-.036.035m.036-.035c.155.16.15.413-.008.567l-2.216 2.15m2.188-2.682a.351.351 0 01-.007.497l-2.219 2.152.038.034m0 0l5.106 4.64m-5.106-4.64l-.036.035 5.142 4.672V20.7m0 0v-3.328a.401.401 0 11.803 0v4.202c0 .59-.48 1.071-1.071 1.071H2.937c-.591 0-1.071-.48-1.071-1.071V2.426c0-.59.48-1.07 1.07-1.07h1.202c.23 0 .436.144.514.36M13.348 20.7l.05.045v-3.373a.351.351 0 11.703 0v4.202c0 .563-.458 1.021-1.021 1.021H2.937a1.022 1.022 0 01-1.021-1.021V2.426c0-.563.458-1.02 1.02-1.02h1.202c.208 0 .396.131.467.327m.047-.017L2.668 17.808v-.07M4.652 1.717l.057.158s0 0 0 0a.522.522 0 00.49.344h.967a.401.401 0 010 .802h-.968a1.326 1.326 0 01-1.24-.862m.694-.442h0l-.047.017m0 0l.057.158a.572.572 0 00.536.377h.968a.351.351 0 110 .702h-.968a1.276 1.276 0 01-1.197-.841l-.008-.021m.612-.375l-.647.425m.035-.05H2.937a.319.319 0 00-.319.318v15.361l.05-.048M3.993 2.108v.05h-.035m.035-.05l-.047.017.008.02s0 0 0 0l.004.013m-1.29 15.58V2.427c0-.148.12-.268.269-.268h1.021m-1.29 15.58l2.058-1.995a.401.401 0 11.558.576l-2.616 2.538m1.29-16.7l-1.34 16.679m.05.021v-.021h-.05m.05.021v1.492m0-1.492l-.015.015-.035-.036m0 0l.05 1.513m0 0l3.33-3.23-3.33 3.23zm4.743-4.602a.448.448 0 00-.136.329m.136-.329l-.136.329m0 0c.002.122.056.24.147.323m-.147-.323l.147.323m0 0s0 0 0 0m0 0h0m0 0l5.872 5.336a.268.268 0 01-.214.107h-1.26L6.61 17.11s0 0 0 0a.448.448 0 00-.612.01l1.424-.72zm-4.754 5.175v-.107l3.65-3.54 4.308 3.915h-7.69a.269.269 0 01-.268-.268z"
      fill="#151F32"
      stroke="#151F32"
      strokeWidth={0.1}
    />
    <path
      d="M8.943 11.65h0a.401.401 0 01.568.009m-.568-.009l.524.54.035.036a.401.401 0 00.009-.567m-.568-.009L5.8 14.7m3.143-3.05L5.8 14.7m3.71-3.041l-.035.034.036-.034s0 0 0 0zm-3.719 3.608l.036-.034-.036.034s0 0 0 0zm0 0A.401.401 0 015.8 14.7m-.009.568L5.8 14.7M5.745 10.876c.221 0 .401-.18.401-.401V8.822a1.574 1.574 0 001.17-1.519c0-.866-.705-1.57-1.571-1.57-.867 0-1.571.704-1.571 1.57 0 .728.497 1.342 1.17 1.52v1.652c0 .222.18.401.4.401zm-.769-3.573a.77.77 0 011.538 0 .77.77 0 01-1.538 0z"
      fill="#151F32"
      stroke="#151F32"
      strokeWidth={0.1}
    />
  </svg>
);

export default SvgSmartphone;
