import * as React from 'react';

const SvgSunrise = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <path
      d="M7.962 6.98c-.228 0-.413.198-.413.443V7.9c0 .245.185.444.413.444.227 0 .412-.2.412-.444v-.476c0-.245-.185-.444-.412-.444zM12.938 9.198a.392.392 0 00-.583 0l-.312.337a.468.468 0 000 .627c.08.087.186.13.292.13a.397.397 0 00.291-.13l.313-.336a.468.468 0 000-.628zM3.88 9.534l-.312-.336a.392.392 0 00-.583 0 .468.468 0 000 .628l.312.336c.08.087.186.13.292.13a.397.397 0 00.291-.13.468.468 0 000-.628zM10.127 3.076L8.253 1.06a.392.392 0 00-.583 0L5.796 3.076a.468.468 0 000 .628.392.392 0 00.583 0l1.17-1.26v3.16c0 .246.185.445.413.445.227 0 .412-.2.412-.444v-3.16l1.17 1.259c.08.087.186.13.292.13a.397.397 0 00.291-.13.468.468 0 000-.628zM14.588 14.112H12.33c-.209-2.397-2.088-4.28-4.368-4.28-2.281 0-4.16 1.883-4.369 4.28H1.335c-.227 0-.412.199-.412.444s.185.444.412.444h13.252c.228 0 .413-.199.413-.444s-.185-.444-.412-.444zm-10.166 0c.205-1.907 1.714-3.392 3.54-3.392 1.825 0 3.334 1.485 3.539 3.392H4.422z"
      fill="#151F32"
    />
  </svg>
);

export default SvgSunrise;
