import * as React from 'react';

const SvgSend = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 24 24'} fill="none" {...props}>
    <path
      d="M3.85 11.472h0a.619.619 0 01.389-.553l.056.14-.056-.14 15.063-6.025a.619.619 0 01.667.137M3.85 11.471l16.013-6.334M3.85 11.472a.619.619 0 00.349.578h0l5.893 2.858M3.85 11.472l6.242 3.436m9.877-9.877l-.106.106m.106-.106s0 0 0 0l-.106.106m.106-.106a.619.619 0 01.137.668h0L14.08 20.76l-.14-.056m5.922-15.568a.469.469 0 01.104.506l-6.025 15.062m0 0l.139.056-.996-.025a.469.469 0 00.421.264h.017m.418-.295a.469.469 0 01-.418.295m0 0l.005.15s0 0 0 0 0 0 0 0l-.005-.15zm-3.431-6.092l2.857 5.893c.104.214.32.349.557.349l-3.414-6.242zm.336-1.212l-4.43-2.148 10.964-4.385-6.534 6.533zm.875.875l6.534-6.533L13.452 19l-2.149-4.43z"
      fill="#151F32"
      stroke="#151F32"
      strokeWidth={0.3}
    />
  </svg>
);

export default SvgSend;
