import styled from 'styled-components';
import ReactTooltip from "react-tooltip";
import { Colors } from "../configs/Colors";

type Position = 'default' | 'start';

export type TooltipStyledProps = {
  position?: Position;
}

export const TooltipStyled = styled(ReactTooltip)<TooltipStyledProps>`
  &.__react_component_tooltip {
    box-sizing: border-box;
    max-width: 400px;
    padding: 12px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    &.show {
      opacity: 1;
    }

    &.place-top::after {
      bottom: -8px;
      border-top-width: 8px;
      ${({ position }) => position === 'start' && `left: 16px;`};      
    }

    &.place-bottom::after {
      top: -8px;
      border-bottom-width: 8px;
      ${({ position }) => position === 'start' && `left: 16px;`};  
    }

    &.place-left::after {
      right: -8px;
      border-left-width: 8px;
    }

    &.place-right::after {
      left: -8px;
      border-right-width: 8px;
    }    

    &.type-light {
      background: ${Colors.generalWhite};
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
      color: ${Colors.generalDark};

      &.place-top::after {
        border-top-color: ${Colors.generalWhite};
      }

      &.place-bottom::after {
        border-bottom-color: ${Colors.generalWhite};
      }

      &.place-left::after {
        border-left-color: ${Colors.generalWhite};
      }

      &.place-right::after {
        border-right-color: ${Colors.generalWhite};
      }
    }

    &.type-dark {
      background: ${Colors.generalDark};
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
      color: ${Colors.generalWhite};

      &.place-top::after {
        border-top-color: ${Colors.generalDark};
      }

      &.place-bottom::after {
        border-bottom-color: ${Colors.generalDark};
      }

      &.place-left::after {
        border-left-color: ${Colors.generalDark};
      }

      &.place-right::after {
        border-right-color: ${Colors.generalDark};
      }
    }

    &.type-success {
      background: ${Colors.green};
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
      color: ${Colors.generalWhite};

      &.place-top::after {
        border-top-color: ${Colors.green};
      }

      &.place-bottom::after {
        border-bottom-color: ${Colors.green};
      }

      &.place-left::after {
        border-left-color: ${Colors.green};
      }

      &.place-right::after {
        border-right-color: ${Colors.green};
      }
    }

    &.type-error {
      background: ${Colors.pink};
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
      color: ${Colors.generalWhite};

      &.place-top::after {
        border-top-color: ${Colors.pink};
      }

      &.place-bottom::after {
        border-bottom-color: ${Colors.pink};
      }

      &.place-left::after {
        border-left-color: ${Colors.pink};
      }

      &.place-right::after {
        border-right-color: ${Colors.pink};
      }
    }
  }

  &.type-info {
    background: ${Colors.blue};
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
    color: ${Colors.generalWhite};

    &.place-top::after {
      border-top-color: ${Colors.blue};
    }

    &.place-bottom::after {
      border-bottom-color: ${Colors.blue};
    }

    &.place-left::after {
      border-left-color: ${Colors.blue};
    }

    &.place-right::after {
      border-right-color: ${Colors.blue};
    }
  }
}  
`;
