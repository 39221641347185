import * as React from 'react';

const SvgEye = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <g clipPath="url(#eye_svg__clip0)" fill="#151F32">
      <path d="M15.805 7.529L13.02 4.745a7.1 7.1 0 00-10.042 0L.195 7.529a.667.667 0 000 .943l2.784 2.784a7.1 7.1 0 0010.042 0l2.784-2.784a.667.667 0 000-.943zm-3.727 2.784a5.767 5.767 0 01-8.156 0L1.61 8l2.313-2.313a5.767 5.767 0 018.156 0L14.39 8l-2.313 2.313z" />
      <path d="M8 4.667a3.333 3.333 0 100 6.667 3.333 3.333 0 000-6.667zM8 10a2 2 0 110-4 2 2 0 010 4z" />
    </g>
    <defs>
      <clipPath id="eye_svg__clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEye;
