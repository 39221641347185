import * as React from 'react';

const SvgHome = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12.333V7.667H7v4.666h2zm-2.333-6a1 1 0 00-1 1v6.334h4.666V7.333a1 1 0 00-1-1H6.667z"
      fill="#9AA0AD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.667 5.652L8 2.022l-4.667 3.63v5.681a1 1 0 001 1h7.334a1 1 0 001-1v-5.68zM7.387.811a1 1 0 011.227 0l5 3.889a1 1 0 01.386.79v5.677a2.5 2.5 0 01-2.5 2.5h-7a2.5 2.5 0 01-2.5-2.5V5.489a1 1 0 01.386-.79l5-3.888z"
      fill="#151F32"
    />
  </svg>
);

export default SvgHome;
