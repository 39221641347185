import * as React from 'react';

const SvgSeed = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 24 24'} fill="none" {...props}>
    <g clipPath="url(#seed_svg__clip0)">
      <path
        d="M6.183 17.491c.06-.048.123-.101.183-.159l-.203.308h-.166l.186-.149zm-.437.15h-.167l-.202-.307c.062.058.124.111.183.158l.186.148zM11.995 23.56l-.002-.001-.002-.001a.041.041 0 01-.014-.018s0 0 0 0 0 0 0 0a.03.03 0 01.002-.02s0 0 0 0 0 0 0 0 0 0 0 0a.035.035 0 01.008-.013s0 0 0 0a.02.02 0 01.003-.002.033.033 0 01.019-.005s0 0 0 0a.036.036 0 01.02.006h.001a.032.032 0 01.009.016s0 0 0 0 0 0 0 0 0 0 0 0a.032.032 0 010 .018v.003s0 0 0 0a.039.039 0 01-.012.013l-.016-.02.016.02a.03.03 0 01-.017.008h-.001s0 0 0 0c-.003 0-.008 0-.014-.004zm.012-.03v.001h0zm.001.002v.001h0z"
        fill="#151F32"
        stroke="#151F32"
      />
      <path
        d="M13.234 14.183a.469.469 0 00.65.132l6.733-4.465c.332.016.66-.074.94-.26.296-.195.513-.48.623-.814a.47.47 0 00.076-.372 1.565 1.565 0 00-.257-.993 1.564 1.564 0 00-.765-.605L17.815 1.65a1.564 1.564 0 00-.26-.94 1.564 1.564 0 00-.814-.624.47.47 0 00-.372-.075 1.563 1.563 0 00-.993.257c-.28.186-.49.453-.605.765L8.038 5.497a.469.469 0 00-.132.65l5.328 8.036zM15.43 1.721c.1-.066.171-.169.198-.286a.631.631 0 01.76-.475.631.631 0 01.482.727c-.02.118.005.24.072.34l3.605 5.438c.066.1.168.17.285.197a.631.631 0 01.48.744l-.004.017a.632.632 0 01-.727.482.469.469 0 00-.34.07l-6.483 4.3-4.81-7.255 6.482-4.299z"
        fill="#3FB65F"
      />
      <path
        d="M14.164 7.782a1.894 1.894 0 00-.09.304c-.264 1.305.962 2.578 1.014 2.631a.468.468 0 00.517.105c.069-.03 1.693-.724 1.958-2.029.248-1.224-.816-2.42-.992-2.607-.104-.234-.79-1.68-2.015-1.928-1.305-.264-2.577.962-2.631 1.014a.469.469 0 00-.105.516c.03.07.724 1.694 2.029 1.958.105.021.21.033.315.036zm2.48.825c-.107.527-.688.963-1.102 1.2-.29-.38-.655-1.007-.549-1.534.107-.527.687-.962 1.1-1.199.265.344.664.976.55 1.533zm-3.807-2.88c.344-.264.976-.663 1.533-.55.527.107.963.688 1.199 1.102-.38.29-1.007.655-1.534.548-.527-.106-.962-.686-1.198-1.1z"
        fill="#3FB65F"
      />
      <mask id="seed_svg__a" fill="#fff">
        <path d="M7.594 12.234a.468.468 0 00.475 0c.124-.073 1.21-.74 1.21-1.691 0-.952-1.086-1.618-1.21-1.691a.469.469 0 00-.475 0c-.124.073-1.212.74-1.212 1.69 0 .952 1.088 1.619 1.212 1.692zm.237-2.408c.239.19.511.476.511.717 0 .225-.256.512-.51.716-.24-.19-.512-.475-.512-.716 0-.226.256-.513.511-.717z" />
      </mask>
      <path
        d="M7.594 12.234a.468.468 0 00.475 0c.124-.073 1.21-.74 1.21-1.691 0-.952-1.086-1.618-1.21-1.691a.469.469 0 00-.475 0c-.124.073-1.212.74-1.212 1.69 0 .952 1.088 1.619 1.212 1.692zm.237-2.408c.239.19.511.476.511.717 0 .225-.256.512-.51.716-.24-.19-.512-.475-.512-.716 0-.226.256-.513.511-.717z"
        fill="#151F32"
      />
      <path
        d="M8.069 12.234l.506.862-.506-.862zm0-3.382l-.507.862.507-.862zm-.475 0l.506.862-.506-.862zm.237.974l.623-.782-.624-.497-.623.498.624.781zm0 1.433l-.623.782.624.498.624-.499-.625-.78zm-.744 1.837c.23.135.487.202.744.202v-2c.093 0 .186.025.27.074l-1.014 1.724zm.744.202c.257 0 .515-.067.744-.202l-1.013-1.724a.532.532 0 01.27-.074v2zm.744-.202c.098-.058.486-.295.862-.68.36-.369.843-1.013.843-1.873h-2c0 .09-.06.256-.275.477a2.365 2.365 0 01-.443.352l1.013 1.724zm1.705-2.553c0-.86-.484-1.505-.843-1.873a4.345 4.345 0 00-.861-.68L7.561 9.714c.026.015.244.148.443.352.215.22.275.386.275.477h2zM8.576 7.99c-.46-.27-1.03-.27-1.49 0L8.1 9.714a.531.531 0 01-.538 0L8.576 7.99zm-1.49 0a4.345 4.345 0 00-.86.68c-.36.368-.844 1.012-.844 1.873h2c0-.091.06-.256.276-.477.199-.204.416-.337.442-.352L7.087 7.99zm-1.704 2.553c0 .86.484 1.504.843 1.873.376.385.764.622.862.68L8.1 11.372a2.365 2.365 0 01-.442-.352c-.216-.221-.276-.386-.276-.477h-2zm1.826.066c.038.03.075.062.108.094a.82.82 0 01.073.08c.016.02.01.018-.001-.009a.559.559 0 01-.046-.231h2c0-.464-.241-.819-.378-.994a3.145 3.145 0 00-.51-.505l-1.246 1.564zm.134-.066a.644.644 0 01.045-.234c.012-.026.017-.028.001-.007a1.435 1.435 0 01-.181.176l1.249 1.562c.171-.137.362-.316.52-.523.133-.174.366-.523.366-.974h-2zm1.113-.066a1.518 1.518 0 01-.109-.095.81.81 0 01-.073-.079c-.016-.02-.01-.018.001.008a.558.558 0 01.046.232h-2c0 .464.242.819.379.994.161.206.352.38.509.504l1.247-1.564zm-.135.066a.643.643 0 01-.045.234c-.012.026-.017.028 0 .007a1.413 1.413 0 01.181-.177l-1.25-1.562a3.106 3.106 0 00-.52.524c-.133.173-.366.523-.366.974h2z"
        fill="#151F32"
        mask="url(#seed_svg__a)"
      />
      <mask id="seed_svg__b" fill="#fff">
        <path d="M10.559 12.754c-.124.073-1.212.74-1.212 1.69 0 .952 1.088 1.619 1.212 1.692a.468.468 0 00.475 0c.124-.073 1.21-.74 1.21-1.691 0-.952-1.086-1.618-1.21-1.691a.469.469 0 00-.475 0zm.237 2.407c-.239-.19-.511-.475-.511-.716 0-.226.256-.513.511-.716.239.19.511.475.511.716 0 .225-.256.512-.51.716z" />
      </mask>
      <path
        d="M10.559 12.754c-.124.073-1.212.74-1.212 1.69 0 .952 1.088 1.619 1.212 1.692a.468.468 0 00.475 0c.124-.073 1.21-.74 1.21-1.691 0-.952-1.086-1.618-1.21-1.691a.469.469 0 00-.475 0zm.237 2.407c-.239-.19-.511-.475-.511-.716 0-.226.256-.513.511-.716.239.19.511.475.511.716 0 .225-.256.512-.51.716z"
        fill="#151F32"
      />
      <path
        d="M10.559 16.136l-.507.862.507-.862zm.475 0l.506.862-.506-.862zm0-3.382l-.507.862.507-.862zm-.238 2.407l-.623.782.624.498.624-.499-.625-.78zm0-1.432l.623-.782-.624-.498-.623.498.624.782zm-.744-1.837a4.345 4.345 0 00-.862.68c-.359.368-.843 1.013-.843 1.873h2c0-.091.06-.256.276-.477.199-.204.416-.337.442-.352l-1.013-1.724zm-1.705 2.553c0 .86.484 1.504.843 1.873.376.385.764.622.862.68l1.013-1.724a2.363 2.363 0 01-.442-.352c-.216-.221-.276-.386-.276-.477h-2zm1.705 2.553c.23.135.487.203.744.203v-2a.53.53 0 01.27.073l-1.014 1.724zm.744.203c.257 0 .515-.068.744-.203l-1.013-1.724a.53.53 0 01.27-.073v2zm.745-.203c.097-.058.485-.295.86-.68.36-.369.844-1.013.844-1.873h-2c0 .091-.06.256-.275.477a2.364 2.364 0 01-.443.352l1.014 1.724zm1.704-2.553c0-.86-.484-1.505-.843-1.873a4.345 4.345 0 00-.861-.68l-1.014 1.724c.026.015.244.148.443.352.215.22.275.386.275.477h2zm-1.704-2.553c-.46-.27-1.03-.27-1.49 0l1.014 1.724a.531.531 0 01-.538 0l1.014-1.724zm-.121 2.487a1.503 1.503 0 01-.109-.095.81.81 0 01-.073-.079c-.016-.02-.01-.018.002.008a.558.558 0 01.045.232h-2c0 .464.242.819.379.993.161.207.352.38.509.505l1.247-1.564zm-.135.066a.643.643 0 01-.045.234c-.012.026-.017.028 0 .007a1.415 1.415 0 01.181-.177l-1.25-1.562a3.108 3.108 0 00-.52.524c-.133.173-.366.523-.366.974h2zm-1.112.066a1.5 1.5 0 01.18.173c.017.021.012.019 0-.008a.559.559 0 01-.046-.231h2c0-.464-.241-.819-.378-.994a3.149 3.149 0 00-.51-.505l-1.246 1.565zm.134-.066a.642.642 0 01.045-.234c.012-.026.017-.028.001-.007a1.415 1.415 0 01-.181.176l1.249 1.562c.171-.137.363-.316.52-.524.133-.173.366-.522.366-.973h-2z"
        fill="#151F32"
        mask="url(#seed_svg__b)"
      />
    </g>
    <defs>
      <clipPath id="seed_svg__clip0">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSeed;
