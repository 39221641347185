import styled from 'styled-components';
import {Colors} from '../configs/Colors';

export const DatePickerHeader = styled.div`
  display: flex;
  flex-direction: column;
  height: 64px;
  width: 100%;
`;

type ContainerProps = {
    placement: 'bottom' | 'left' | 'top'
}

const renderPlacement = (value: ContainerProps['placement']) => {
  const variants = {
    'bottom': 'margin-top: 70px; margin-left: 310px;',
    'left': '',
    'top': 'margin-left: 310px; margin-top: -380px;'
  }
  return variants[value] || variants['left']
}

export const ContainerWrapper = styled.div<ContainerProps>`
  padding: 26px;
  right: 0 !important;
  bottom: 0 !important;
  border: none;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  ${({placement}) => renderPlacement(placement)};
`;

export const Wrapper = styled.div`
  //.react-datepicker__month-container {
  //  padding: 26px;
  //}
  & * {
    outline: none;
  }
  position: relative;
  .react-datepicker-popper {
    transform: none !important;
    left: -312px !important;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__header {
    background: #fff;
  }
  .react-datepicker__header {
    border-bottom: none !important;
    padding-top: 0 !important;
  }
  //.react-datepicker__month {
  //  display: flex;
  //}
  // .react-datepicker__day {
  //   font-weight: normal;
  //   font-size: 14px;
  //   line-height: 16px;
  //   color: ${Colors.secondaryDarkGray};
  //   width: 26px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   height: 27px;
  // }
  .react-datepicker__day--keyboard-selected {
    color: ${Colors.generalWhite} !important;
    background: ${Colors.green};
  }
  .react-datepicker__day {
    border-radius: 15px;
    font-size: 14px;
    line-height: 27px;
    color: ${Colors.secondaryDarkGray};
  }
  .react-datepicker__day--selected {
    color: ${Colors.generalWhite};
    background: ${Colors.green};
  }
  .react-datepicker__day-names {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: ${Colors.generalDark};
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  
`;

export const SeparatorWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Label = styled.p`
  font-weight: 600;
  font-size: 14px;
  margin-left: 7.5px;
  margin-right: 7.5px;
  line-height: 16px;
  text-align: center;
  color: ${Colors.secondaryDarkGray};
`

export const InputField = styled.input`
  background: ${Colors.secondaryDarkGray};
  border: 0.5px solid ${Colors.borderGray};
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px 16px;
  height: 40px;
`;
export const LabelField = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.secondaryDarkGray};
  margin: 0 0 4px;
`;
export const Error = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: ${Colors.pink};
`;
