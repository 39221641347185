import * as React from 'react';

const SvgName = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <path
      d="M13.396 14H2.604C1.72 14 1 13.246 1 12.32V4.68C1 3.753 1.72 3 2.604 3h3.5c.242 0 .438.205.438.458a.448.448 0 01-.438.459h-3.5c-.402 0-.729.342-.729.763v7.64c0 .42.327.763.73.763h10.79c.403 0 .73-.342.73-.764V4.68c0-.42-.327-.763-.73-.763h-3.5a.448.448 0 01-.437-.459c0-.253.196-.458.438-.458h3.5C14.28 3 15 3.753 15 4.68v7.64c0 .926-.72 1.68-1.604 1.68z"
      fill="#151F32"
    />
    <path
      d="M9.125 5h-2.25C6.392 5 6 4.572 6 4.045v-1.09C6 2.428 6.392 2 6.875 2h2.25c.483 0 .875.428.875.955v1.09c0 .527-.392.955-.875.955zm-2.25-2.182c-.069 0-.125.061-.125.137v1.09c0 .076.056.137.125.137h2.25c.07 0 .125-.061.125-.137v-1.09c0-.076-.056-.137-.125-.137h-2.25zM5.5 9C4.673 9 4 8.327 4 7.5S4.673 6 5.5 6 7 6.673 7 7.5 6.327 9 5.5 9zm0-2.1a.601.601 0 000 1.2.601.601 0 000-1.2zM7.583 12c-.23 0-.416-.252-.416-.563v-.374c0-.517-.312-.938-.695-.938H4.528c-.383 0-.695.42-.695.938v.374c0 .311-.186.563-.416.563-.23 0-.417-.252-.417-.563v-.374C3 9.925 3.685 9 4.528 9h1.944C7.315 9 8 9.925 8 11.063v.374c0 .311-.187.563-.417.563zM12.571 8H9.43C9.192 8 9 7.776 9 7.5s.192-.5.429-.5h3.142c.237 0 .429.224.429.5s-.192.5-.429.5zM12.571 12H9.43C9.192 12 9 11.776 9 11.5s.192-.5.429-.5h3.142c.237 0 .429.224.429.5s-.192.5-.429.5z"
      fill="#151F32"
    />
  </svg>
);

export default SvgName;
