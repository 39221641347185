import * as React from 'react';

const SvgSun = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 28 28'} fill="none" {...props}>
    <g
      clipPath="url(#sun_svg__clip0)"
      filter="url(#sun_svg__filter0_d)"
      fill="#151F32"
    >
      <path d="M14 17.333a5.333 5.333 0 100-10.666 5.333 5.333 0 000 10.666zM14 0c-.736 0-1.333.597-1.333 1.333v1.334a1.333 1.333 0 002.666 0V1.333C15.333.597 14.736 0 14 0zM14 20c-.736 0-1.333.597-1.333 1.333v1.334a1.333 1.333 0 002.666 0v-1.334c0-.736-.597-1.333-1.333-1.333zM24.667 10.667h-1.334a1.333 1.333 0 000 2.666h1.334a1.333 1.333 0 100-2.666zM4.667 10.667H3.333a1.333 1.333 0 000 2.666h1.334a1.333 1.333 0 100-2.666zM22.453 3.515c-.517-.5-1.336-.5-1.853 0l-.943.942a1.333 1.333 0 001.886 1.886l.942-.943a1.333 1.333 0 00-.032-1.885zM8.375 17.657a1.333 1.333 0 00-1.918 0l-.942.943A1.333 1.333 0 107.4 20.485l.943-.942c.53-.512.544-1.356.032-1.886zM22.485 18.6l-.942-.943a1.333 1.333 0 00-1.886 1.886l.943.942a1.333 1.333 0 001.885-1.885zM8.375 4.49a1.638 1.638 0 00-.032-.033L7.4 3.515A1.333 1.333 0 005.515 5.4l.942.943A1.333 1.333 0 108.375 4.49z" />
    </g>
    <defs>
      <clipPath id="sun_svg__clip0">
        <path fill="#fff" transform="translate(2)" d="M0 0h24v24H0z" />
      </clipPath>
      <filter
        id="sun_svg__filter0_d"
        x={0}
        y={0}
        width={28}
        height={28}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default SvgSun;
