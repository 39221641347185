import * as React from 'react';

const SvgCatalog = (props: React.SVGProps<any>) => (

    <svg viewBox={ '0 0 16 16' } fill="none" { ...props }>
        <path d="M6 1C3.24295 1 1 3.24295 1 6C1 8.75706 3.24295 11 6 11C8.75706 11 11 8.75704 11 6C11 3.24295 8.75706 1 6 1ZM6 9.9071C3.84557 9.9071 2.0929 8.15443 2.0929 6C2.0929 3.84567 3.84557 2.0929 6 2.0929C8.15443 2.0929 9.9071 3.84567 9.9071 6C9.9071 8.15443 8.15443 9.9071 6 9.9071Z" fill="#151F32"/>
        <path d="M6.43257 6.00603V3.91042C6.43257 3.67643 6.24296 3.48682 6.00906 3.48682C5.77508 3.48682 5.58545 3.67643 5.58545 3.91042V6.14136C5.58545 6.14802 5.58709 6.15427 5.58741 6.16093C5.58184 6.27611 5.62152 6.39306 5.70949 6.48105L7.28711 8.05853C7.45258 8.224 7.72077 8.224 7.88612 8.05853C8.05148 7.89306 8.05159 7.62487 7.88612 7.45951L6.43257 6.00603Z" fill="#151F32"/>
    </svg>
);

export default SvgCatalog;
