

import * as React from 'react';

const SvgQuestion = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 14 14'} fill="none" {...props}>
    <path d="M6.97266 10.9648C6.67059 10.9648 6.42578 10.72 6.42578 10.418C6.42578 10.1159 6.67059 9.87109 6.97266 9.87109C7.27472 9.87109 7.51953 10.1159 7.51953 10.418C7.51953 10.72 7.27472 10.9648 6.97266 10.9648ZM7.51953 8.50391V7.76958C8.57697 7.51376 9.30959 6.55438 9.26782 5.4695C9.22243 4.28816 8.25322 3.32761 7.06131 3.28296C6.4245 3.25882 5.82315 3.48697 5.36856 3.92479C4.92177 4.35513 4.67578 4.93256 4.67578 5.55078C4.67578 5.85284 4.92059 6.09766 5.22266 6.09766C5.52472 6.09766 5.76953 5.85284 5.76953 5.55078C5.76953 5.23248 5.89653 4.9348 6.12724 4.71263C6.36586 4.48288 6.68277 4.36336 7.02029 4.37585C7.64418 4.39935 8.15132 4.89816 8.17493 5.51158C8.19682 6.08324 7.80226 6.58835 7.23648 6.71246C6.75914 6.81725 6.42578 7.22975 6.42578 7.71574V8.50391C6.42578 8.80597 6.67059 9.05078 6.97266 9.05078C7.27472 9.05078 7.51953 8.80597 7.51953 8.50391ZM10.622 12.9912C10.8804 12.8347 10.9629 12.4984 10.8064 12.2402C10.6499 11.9818 10.3136 11.8992 10.0553 12.0557C9.13676 12.6121 8.08018 12.9062 7 12.9062C3.74332 12.9062 1.09375 10.2567 1.09375 7C1.09375 3.74332 3.74332 1.09375 7 1.09375C10.2567 1.09375 12.9062 3.74332 12.9062 7C12.9062 8.16457 12.56 9.29805 11.9048 10.2782C11.7369 10.5292 11.8044 10.8688 12.0554 11.0367C12.3065 11.2046 12.6462 11.1371 12.814 10.886C13.59 9.72551 14 8.38171 14 7C14 5.13026 13.2719 3.37236 11.9498 2.05025C10.6276 0.728134 8.86974 0 7 0C5.13026 0 3.37236 0.728134 2.05025 2.05025C0.728134 3.37236 0 5.13026 0 7C0 8.86974 0.728134 10.6276 2.05025 11.9498C3.37236 13.2719 5.13026 14 7 14C8.28003 14 9.5325 13.6512 10.622 12.9912Z" fill="#3FB65F"/>
  </svg>
);

export default SvgQuestion;