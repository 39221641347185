import * as React from 'react';

const SvgArrowtop = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 12 12'} fill="none" {...props}>
    <path
      d="M10.97 8.026a.727.727 0 00-.218-.528L6.677 3.325c-.185-.185-.375-.272-.61-.277a.799.799 0 00-.609.277l-4.08 4.173a.736.736 0 00-.218.528c0 .424.337.762.756.762a.784.784 0 00.555-.24l3.597-3.7 3.59 3.7a.756.756 0 001.311-.522z"
      fill="#18191B"
    />
  </svg>
);

export default SvgArrowtop;
