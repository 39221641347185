import * as React from 'react';

const SvgCalendar = (props: React.SVGProps<any>) => (
    <svg
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <path
          d="M7.659 1.5c.156 0 .284.117.305.268l.003.042v.35c1.263.087 2.037.904 2.033 2.223v3.772c0 1.398-.869 2.234-2.265 2.234h-3.47C2.87 10.389 2 9.539 2 8.124V4.383c0-1.319.776-2.136 2.037-2.222v-.35c0-.172.138-.311.308-.311.156 0 .284.117.305.268l.003.042v.343H7.35V1.81c0-.171.138-.31.308-.31zm1.725 3.681H2.615v2.943c0 1.033.554 1.607 1.549 1.643l.101.002h3.47c1.063 0 1.65-.565 1.65-1.614V5.181zM7.826 7.783c.17 0 .308.138.308.31a.31.31 0 01-.266.307l-.042.003a.31.31 0 01-.045-.617l.045-.003zm-1.82 0c.17 0 .307.138.307.31a.31.31 0 01-.266.307l-.041.003a.31.31 0 01-.046-.617l.046-.003zm-1.825 0c.17 0 .308.138.308.31a.31.31 0 01-.266.307l-.045.003a.309.309 0 01-.308-.31.31.31 0 01.266-.307l.045-.003zm3.645-1.607c.17 0 .308.139.308.31a.31.31 0 01-.266.307l-.042.003a.31.31 0 01-.045-.617l.045-.003zm-1.82 0c.17 0 .307.139.307.31a.31.31 0 01-.266.307l-.041.003a.31.31 0 01-.046-.617l.046-.003zm-1.825 0c.17 0 .308.139.308.31a.31.31 0 01-.266.307l-.045.003a.309.309 0 01-.308-.31.31.31 0 01.266-.307l.045-.003zm3.17-3.403H4.652v.398c0 .17-.137.31-.307.31a.309.309 0 01-.305-.268l-.003-.042v-.389c-.917.077-1.422.632-1.422 1.6v.179h6.77v-.18c.003-.969-.499-1.522-1.418-1.599v.389c0 .17-.138.31-.308.31a.309.309 0 01-.305-.268l-.003-.042v-.398z"
          fill="#9AA0AD"
      />
    </svg>
);
export default SvgCalendar;
