import * as React from 'react';

const SvgVeterinary = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 24 24'} fill="none" {...props}>
    <path
      d="M4.066 14.475l1.964-1.263h0l.054.084a.327.327 0 01.25-.044l-2.268 1.223zm0 0l.537-1.61h0a.427.427 0 00-.18-.5c-1.763-1.088-2.812-2.92-2.812-4.9 0-3.223 2.777-5.854 6.202-5.854 1.036 0 2.062.246 2.965.711h0c.21.108.467.025.575-.184 0 0 0 0 0 0l-.089-.046-7.198 12.383zm7.91-11.916a.427.427 0 00.041.602h0c1.27 1.107 1.998 2.675 1.998 4.304 0 3.222-2.777 5.853-6.202 5.853-.491 0-.982-.055-1.457-.163l5.695-10.53m-.075-.066l.075.065m-.075-.065s0 0 0 0l.075.065m-.075-.065a.427.427 0 01.602-.042l-.066.076a.327.327 0 00-.461.031m8.96 20.508h0a.43.43 0 01-.52.044s0 0 0 0l-2.835-1.823a7.452 7.452 0 01-1.47.146c-3.885 0-7.055-3.004-7.055-6.707 0-.25.014-.499.043-.746a7.407 7.407 0 01-2.83-.02l-2.835 1.823a.43.43 0 01-.52-.044.43.43 0 01-.117-.45s0 0 0 0l.816-2.448L21.01 23.132zm0 0a.43.43 0 00.117-.45m-.118.45l.118-.45m0 0l-.816-2.448.816 2.447zM14.826 8.21a7.65 7.65 0 00-.103.02l-.014.105c.034-.008.07-.014.104-.021l.013-.104zm4.571 11.981h0l.537 1.611-1.964-1.263a.427.427 0 00-.326-.057h0a6.584 6.584 0 01-1.457.163c-3.425 0-6.202-2.631-6.202-5.853 0-.327.029-.653.085-.972 2.246-.723 3.99-2.494 4.581-4.7.5-.12 1.015-.182 1.536-.182 3.425 0 6.202 2.631 6.202 5.854 0 1.98-1.05 3.812-2.811 4.9a.427.427 0 00-.18.5z"
      fill="#151F32"
      stroke="#151F32"
      strokeWidth={0.2}
    />
    <path
      d="M16.286 14.521c-1.442 0-2.622 1.084-2.622 2.425 0 .613.248 1.106.702 1.444.453.337 1.108.517 1.92.517.811 0 1.466-.18 1.92-.517.454-.338.701-.83.701-1.444 0-1.34-1.18-2.425-2.621-2.425zm0 3.707c-.36 0-.838-.043-1.223-.227a1.199 1.199 0 01-.479-.387 1.133 1.133 0 01-.192-.668c0-.959.845-1.746 1.894-1.746 1.048 0 1.894.787 1.894 1.746 0 .28-.073.498-.193.668a1.2 1.2 0 01-.478.387c-.386.184-.863.227-1.223.227zM15.1 11.598c-.382 0-.695.237-.91.54-.214.304-.338.684-.338.99a1.249 1.249 0 002.495 0c0-.306-.123-.686-.338-.99-.214-.303-.527-.54-.91-.54zm0 1.973a.444.444 0 01-.443-.443.972.972 0 01.308-.662c.052-.044.098-.063.135-.063.036 0 .082.02.134.063a.972.972 0 01.309.662.444.444 0 01-.443.443zM17.475 13.128a.444.444 0 00.886 0h-.886zm0 0c0-.155.066-.34.159-.486m-.16.486l.16-.486m0 0a.769.769 0 01.15-.176m-.15.176l.15-.176m0 0c.051-.044.098-.063.134-.063m-.135.063l.135-.063m0 0c.036 0 .082.02.134.063m-.134-.063l.134.063m0 0a.769.769 0 01.15.176m-.15-.176l.15.176m0 0c.093.147.159.331.159.486l-.16-.486zm.963.486v0c0-.306-.123-.686-.338-.99-.214-.303-.527-.54-.91-.54-.381 0-.694.237-.909.54-.214.304-.338.684-.338.99a1.25 1.25 0 002.495 0zM19.729 14.819a.444.444 0 00.886 0h-.886zm0 0c0-.155.066-.34.16-.486m-.16.486l.16-.486m0 0a.77.77 0 01.149-.177m-.15.177l.15-.177m0 0c.052-.043.098-.063.134-.063m-.134.063l.134-.063m0 0c.037 0 .083.02.135.063m-.135-.063l.135.063m0 0a.77.77 0 01.15.177m-.15-.177l.15.177m0 0c.092.146.158.331.158.486l-.159-.486zm.963.486v0c0-.307-.123-.686-.338-.99-.215-.304-.528-.54-.91-.54s-.695.236-.91.54c-.214.304-.338.683-.338.99a1.249 1.249 0 002.495 0zM12.845 13.29c-.382 0-.695.235-.91.54-.214.303-.337.682-.337.989 0 .688.56 1.247 1.247 1.247.688 0 1.248-.56 1.248-1.247 0-.307-.123-.686-.338-.99-.215-.304-.527-.54-.91-.54zm0 1.972a.444.444 0 01-.443-.444.972.972 0 01.309-.662c.052-.043.098-.063.134-.063.037 0 .083.02.135.063a.972.972 0 01.309.662.444.444 0 01-.444.444z"
      fill="#3FB65F"
      stroke="#3FB65F"
      strokeWidth={0.1}
    />
    <path
      d="M10.873 5.586H9.02V3.734a.402.402 0 00-.403-.402H6.364a.402.402 0 00-.402.402v1.852H4.11a.402.402 0 00-.403.403v2.254c0 .222.18.402.403.402h1.852v1.853c0 .222.18.402.402.402h2.254c.223 0 .403-.18.403-.402V8.645h1.852c.222 0 .402-.18.402-.402V5.99a.402.402 0 00-.402-.403zm-.402 2.255H8.618a.402.402 0 00-.402.402v1.852h-1.45V8.243a.402.402 0 00-.402-.402H4.512V6.39h1.852c.222 0 .402-.18.402-.402V4.136h1.45V5.99c0 .222.18.402.402.402h1.853v1.45z"
      fill="#151F32"
      stroke="#151F32"
      strokeWidth={0.1}
    />
  </svg>
);

export default SvgVeterinary;
