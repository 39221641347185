import styled from 'styled-components';
import {Colors} from '../configs/Colors';

export type InputFieldStyledProps = {
    error?: string;
    focus?: boolean;
}

const defaultScheme = `
 border-color: ${Colors.borderGray};
 color: ${Colors.generalDark};
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Label = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 4px;
  color: ${Colors.secondaryDarkGray};
`
export const IconWrapper = styled.div`
  height: 40px;
  width: 27px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 20px;
`;

const hasErrorScheme = `
 border-color: ${Colors.pink};
 color: ${Colors.pink};
`;

const renderColor = (error: boolean) => {
    return error ? hasErrorScheme : defaultScheme;
};

export const InputField = styled.input<InputFieldStyledProps>`
  background: ${Colors.secondaryGray};
  border: 1px solid;
  border-radius: 14px;
  font-size: 12px;
  width: 100%;
  height: 40px;
  padding: 12px 27px 12px 16px;
  box-sizing: border-box;
  outline: none;

  ${({error}) => renderColor(!!error)};
  
  ::placeholder {
    color: ${Colors.secondaryDarkGray};
  }
`;

export const ErrorMessage = styled.div`
  color: ${Colors.pink};
  font-size: 12px;
  padding: 4px 0;
`;
