import * as React from 'react';

const SvgCatalog = (props: React.SVGProps<any>) => (

    <svg viewBox={ '0 0 16 16' } fill="none" { ...props }>
        <path d="M2.19456 9.41181C2.39572 9.26319 2.43915 8.97845 2.29159 8.77583C1.71096 7.97859 1.40407 7.03274 1.40407 6.04055C1.40407 3.48791 3.46579 1.41119 6 1.41119C8.5342 1.41119 10.5959 3.48791 10.5959 6.04055C10.5959 7.03274 10.289 7.97859 9.70839 8.77585C9.56083 8.97847 9.60428 9.26321 9.80542 9.41183C10.007 9.56077 10.2896 9.51629 10.4368 9.3141C12.0068 7.15834 11.8495 4.09885 9.88857 2.12372C7.73943 -0.0410769 4.26087 -0.0414019 2.11143 2.12372C0.150537 4.09885 -0.00680435 7.15836 1.56319 9.3141C1.71073 9.51668 1.99341 9.56042 2.19456 9.41181Z" fill="#151F32"/>
        <path d="M11.0482 10.5H0.95176C0.70226 10.5 0.5 10.7239 0.5 11C0.5 11.2761 0.70226 11.5 0.95176 11.5H11.0482C11.2977 11.5 11.5 11.2761 11.5 11C11.5 10.7239 11.2977 10.5 11.0482 10.5Z" fill="#151F32"/>
        <path d="M5.53862 4.32972C5.94866 3.88431 6.65246 4.2037 6.65246 4.83089C6.65246 5.08513 6.84219 5.29123 7.07623 5.29123C7.31027 5.29123 7.5 5.08513 7.5 4.83089C7.5 4.09227 7.04509 3.46727 6.42377 3.2681V2.96033C6.42377 2.7061 6.23404 2.5 6 2.5C5.76596 2.5 5.57623 2.7061 5.57623 2.96033V3.26736C4.85449 3.497 4.38877 4.28252 4.55337 5.10834C4.6547 5.61668 4.97874 6.03874 5.42016 6.23737L6.25551 6.61322C6.64181 6.78703 6.7576 7.32562 6.47785 7.65173C6.07807 8.11737 5.34754 7.81368 5.34754 7.16909C5.34754 6.91485 5.15781 6.70875 4.92377 6.70875C4.68973 6.70875 4.5 6.91485 4.5 7.16909C4.5 7.88832 4.93759 8.52944 5.57623 8.73155V9.03967C5.57623 9.2939 5.76596 9.5 6 9.5C6.23404 9.5 6.42377 9.2939 6.42377 9.03967V8.73091C6.66019 8.65605 6.8768 8.519 7.05512 8.32726C7.79137 7.53612 7.52853 6.18949 6.57984 5.76263L5.74449 5.38678C5.35377 5.21098 5.23643 4.6586 5.53862 4.32972Z" fill="#151F32"/>
    </svg>
);

export default SvgCatalog;
