import * as React from 'react';

const SvgCatalog = (props: React.SVGProps<any>) => (

    <svg viewBox={ '0 0 16 16' } fill="none" { ...props }>
        <path
            d="M8.1236 6.6135L3.3865 1.8764L4.76292 0.5H0.5V4.76291L1.8764 3.3865L6.6135 8.12358L5.23709 9.5H9.5V5.23709L8.1236 6.6135Z"
            fill="#151F32"/>
    </svg>
);

export default SvgCatalog;
