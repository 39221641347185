import * as React from 'react';

const SvgEmail = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 16 16'} fill="none" {...props}>
    <path
      d="M14.515 3.392a1.652 1.652 0 00-.585-.368h-.017l-.15-.05h-.034c-.05-.017-.1-.017-.15-.034h-.034c-.067 0-.117-.016-.184-.016H2.656c-.067 0-.117 0-.184.016h-.034c-.05 0-.1.017-.15.034h-.034l-.15.05h-.017a1.928 1.928 0 00-.585.368c-.017 0-.017.017-.034.033l-.05.05c-.017.034-.05.05-.067.084l-.033.034s0 .016-.017.016A1.644 1.644 0 001 4.546v6.891c0 .435.167.837.452 1.121 0 .017.016.017.016.034.301.3.703.484 1.154.484h10.739c.451 0 .853-.184 1.154-.485.301-.3.485-.702.485-1.154v-6.89c0-.453-.184-.854-.485-1.155zm-12.026.385c.05-.017.1-.017.15-.017h10.722c.05 0 .1 0 .15.017l.15.05L11.137 5.9 8.31 8.192l-.016.017a.454.454 0 01-.569 0L4.881 5.901 2.338 3.827l.15-.05zm11.658 7.66a.759.759 0 01-.234.552.797.797 0 01-.552.234H2.656a.759.759 0 01-.552-.234l-.017-.016a.744.744 0 01-.217-.536V4.563L4.345 6.57 5.901 7.84l-2.442 2.325c-.167.167-.184.435-.017.602.167.167.435.184.602.017L6.57 8.376l.619.502c.25.2.535.301.82.285.3 0 .585-.1.819-.302l.017-.016.602-.485 2.542 2.425c.167.167.435.15.602-.017.168-.167.15-.435-.016-.602l-2.46-2.342 1.556-1.254 2.476-2.007v6.874z"
      fill="#151F32"
    />
  </svg>
);

export default SvgEmail;
