import * as React from 'react';

const SvgArrowright = (props: React.SVGProps<any>) => (
  <svg viewBox={'0 0 12 12'} fill="none" {...props}>
    <path
      d="M4.554 10.82c.212 0 .38-.076.527-.218l4.167-4.07c.185-.184.272-.374.278-.608a.798.798 0 00-.278-.608L5.081 1.24a.735.735 0 00-.527-.218.755.755 0 00-.76.755c0 .207.086.403.238.555l3.695 3.591L4.032 9.51a.783.783 0 00-.239.555c0 .418.337.755.76.755z"
      fill="#18191B"
    />
  </svg>
);

export default SvgArrowright;
